import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha"

const sitekey = "6Lfp80MfAAAAAFTYtKH4SBDPpTDHa-mAGuoAKz1n";  // change to your sitekey


export default function App() {
  const gateway_url = "https://reo65gr1uf.execute-api.eu-west-2.amazonaws.com/prod";
  const [submitted, setSubmitted] = useState(false);
  const [token, setToken] = useState(false);

  const onChange = (value) => {
    setToken(value);
  }

  const { register
    , handleSubmit
    , reset
    , setError
    , formState: { errors } } = useForm();

  const onSubmit = async data => {
    try {
      await fetch(gateway_url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      );
    }
  };

  const showSubmitError = msg => <div className="alert alert-warning" role="alert">An error has occured: {msg}</div>;

  const showThankYou = (
    <div className="msg-confirm">
      <p>Thank you, we'll get back to you soon.</p>
      <Button type="button" onClick={() => setSubmitted(false)}>
        Send another message?
      </Button>
    </div>
  );

 const showForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className="form-group">
      <label htmlFor="name">Name</label>
      <input className="form-control" placeholder="Your Name" {...register('name', {required: true, maxLength: 40})} />
      {errors.name?.type === 'required' && "Name is required"}
    </div>

    <div className="form-group">
      <label htmlFor="question">Message</label>
      <textarea className="form-control" placeholder="Your Message" {...register('question', {required: true})} />
      {errors.question?.type === 'required' && "A message is required"}
    </div>

    <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
        className="form-control" placeholder="email@email.com" type="email" {...register('email',{ required: true })}
      />
      {errors.email?.type === 'required' && "A valid email is required"}
    </div>
    <div className="form-group">
    <ReCAPTCHA
            sitekey={sitekey}
            onChange={onChange}
          />
    </div>
    <div className="form-group">
      <Button type="submit" variant="primary" disabled={!token}>
        Send Message
      </Button>
    </div>
  </form>
 )

  return (
    <div className="page contact-page mb-5">
      <div className="text-side">
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>
      <div className="form-side">{submitted ? showThankYou : showForm}</div>
    </div>
  );
}