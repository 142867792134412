import React from 'react';
import Layout from '../components/Layout';
import GoogleMaps from '../components/GoogleMaps';
import Contact from '../components/Contact'
import rd1 from '../assets/images/rd1.jpg';
import rd7 from '../assets/images/rd7.jpg';
import rd4 from '../assets/images/rd4.jpg';
import rd5 from '../assets/images/rd5.jpg';
import rd6 from '../assets/images/rd6.jpg';


const IndexPage = () => (
  <Layout activeLink="home">
    {<section className="page-section cta">
      <div className="container">
        <div className="col-xl-9 mx-auto">
          <h2 className="section-heading text-center">
              This circle has now closed <br/> Thank you for all your support over the years
          </h2>
        </div>
      </div>
    </section>}
    <section className="page-section clearfix">
      <div className="container">
        <div className="intro">
          <img
            className="intro-img img-fluid mb-3 mb-lg-0 rounded"
            src={rd1}
            alt="A circle of cushions surrounds a small table"
          />
          <div className="intro-text left-0 text-center bg-faded p-5 rounded">
            <h2 className="section-heading mb-4">
              <span className="section-heading-upper">A Warm Welcome </span>
              <span className="section-heading-lower">Awaits</span>
            </h2>
            <p className="mb-3">
            We invite you to join us in circle once a month to share food and friendship.
            </p>
            <p className="mb-3">
            We welcome women of all ages, abilities, backgrounds, and traditions as we gather together as equals and talk openly about our shared experiences in life. 
Our circle is open to all who live as women.
            </p>
            <div className="intro-button mx-auto">
              <a className="btn btn-primary btn-xl" href="#cta">
                Monthly Sessions
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section">
      <div className="container">
        <div className="product-item">
          <div className="product-item-title d-flex">
            <div className="bg-faded p-5 d-flex ml-auto rounded">
              <h2 className="section-heading mb-0">
                <span className="section-heading-upper">
                  Our time to rest
                </span>
                <span className="section-heading-lower">
                  and just be
                </span>
              </h2>
            </div>
          </div>
          <img
            className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
            src={rd6}
            alt="A small table with an arragenment of candles and crystals"
          />
          <div className="product-item-description d-flex mr-auto">
            <div className="bg-faded p-5 rounded">
              <p className="mb-0">
              We light candles to create a sacred space, we may do some gentle relaxation, or listen to uplifting music, and we always have comforting hot drinks and tasty snacks.
Each woman gets a chance to speak, and be heard, without interruption or judgement. 
Everything said in circle is in confidence, and we connect in mutual trust and respect to create a sisterhood. There is nothing required of you, if you do not feel like talking that is totally respected and you can still come and enjoy the supportive energies of the other women.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section">
      <div className="container">
        <div className="product-item">
          <div className="product-item-title d-flex">
            <div className="bg-faded p-5 d-flex mr-auto rounded">
              <h2 className="section-heading mb-0">
                <span className="section-heading-upper">
                  Wellbeing
                </span>
                <span className="section-heading-lower">
                  for Women
                </span>
              </h2>
            </div>
          </div>
          <img
            className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
            src={rd5}
            alt="An arragenment of candles and crystals"
          />
          <div className="product-item-description d-flex ml-auto">
            <div className="bg-faded p-5 rounded">
              <p className="mb-0">
              Red tents, sometimes known as sharing circles, moon lodges, goddess groups, or simply women’s circles, have ancient origins. Women have been gathering in circles this way for centuries in many cultures around the world, and we believe this plays an important part in our wellbeing, and creates a restorative and nourishing environment within our community.
These circle gatherings are growing rapidly across the world once again as people feel the need to connect with each other on a deeper level. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section">
      <div className="container">
        <div className="product-item">
          <div className="product-item-title d-flex">
            <div className="bg-faded p-5 d-flex ml-auto rounded">
              <h2 className="section-heading mb-0">
                <span className="section-heading-upper">
                  Ethos
                </span>
                <span className="section-heading-lower">
                  The Red Tent
                </span>
              </h2>
            </div>
          </div>
          <img
            className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
            src={rd4}
            alt="Candles and a goddess sculpture"
          />
          <div className="product-item-description d-flex mr-auto">
            <div className="bg-faded p-5 rounded">
              <p className="mb-0">
              Our circle is organised voluntarily by the sisters who share the same vision and values, and is run for the community, not for profit. We aim to be as accessible as possible, therefore we do not have an admission fee, but donations are appreciated to help us cover costs (we suggest £4 per person)
              </p>
              <p className="mb-0">
              Our venue is fully accessible and we follow the inclusivity ethics set by the Red Tent Directory.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section">
      <div className="container">
        <div className="product-item">
          <div className="product-item-title d-flex">
            <div className="bg-faded p-5 d-flex mr-auto rounded">
              <h2 className="section-heading mb-0">
                <span className="section-heading-upper">
                  We would love you to
                </span>
                <span className="section-heading-lower">
                  Join us
                </span>
              </h2>
            </div>
          </div>
          <img
            className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
            src={rd7}
            alt="Someone plays a tibetan singing bowl"
          />
          <div className="product-item-description d-flex ml-auto">
            <div className="bg-faded p-5 rounded">
            <p className="mb-5">
              We warmly invite you to join us on the second Thursday of each month at 7:30pm in The Reading Room.
You may wish to wear red clothing or accessories to symbolically connect with your feminine essence.
You could bring a snack to share, or make a small donation, but most of all just bring your beautiful self to join us in the sacred circle of sisterhood.
            </p>
            <p className="mb-5">
              Spaces are limited, so if you would like to attend, please get in touch via the form below and reserve your free place. 
            </p>
            <p className="mb-5">
              The Reading Room<br/>
              17a High Street<br/>
              Chinnor<br/>
              Oxfordshire<br/>
              OX39 4DJ
            </p>
            <GoogleMaps />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner rounded">
              <h2 className="section-heading mb-4">
                <span className="section-heading-lower" id="cta">Get In Touch</span>
              </h2>

            <Contact />

            </div>
            <div className="cta-inner rounded">
            <p className="mb-5">
            Discover the magic of the Red Tent community in Oxfordshire and Buckinghamshire. Our mission is to provide a safe and supportive space for women to connect, share, and heal. We invite women from across the area, including the local towns and villages of Chinnor, Princes Risborough, Stokenchurch, Thame, and Watlington, to join us in our sacred sharing circle.
            </p>
            <p className="mb-5">
            Book your spot at our next Red Tent gathering and tap into your inner wisdom and strength while celebrating your femininity in a nurturing and uplifting environment of sisterhood.
            </p>
            <p className="mb-5">
            Please note that this event is organized by volunteers from the community and is not managed by any professional organization. By attending the event, you acknowledge that you do so at your own risk and take full responsibility for your safety and well-being. The event organizers and volunteers will not be held liable for any injury, loss, or damage incurred as a result of your participation. Please take all necessary precautions and exercise caution while participating in the event.
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
