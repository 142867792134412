import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => <div style={{ border: 'solid 1px #333', display: 'inline-block', padding: '3px' }}> {text}</div>;

const GoogleMaps = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					other {
                        googleMapsKey
					}
				}
			}
		}
	`);
	const { googleMapsKey } = data.site.siteMetadata.other;

	const mapConfig = {
		center: {
			lat: 51.704878,
			lng: -0.906909,
		},
		zoom: 17,
		options: {
			mapTypeControl: true,
			streetViewControl: true
		}
	};

	return (
		<div id="google-map-section" style={{ height: '600px', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: googleMapsKey }}
				defaultCenter={mapConfig.center}
				defaultZoom={mapConfig.zoom}
				options={mapConfig.options}
			>
				<Marker lat={mapConfig.center.lat + 0.0001} lng={mapConfig.center.lng} text="The Reading Room" />
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMaps;